import React, { useEffect, useState } from "react";
import { auth, firestore } from "../../firebase";

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [accountLinked, setAccountLinked] = useState(false);
  const [authMethod, setAuthMethod] = useState(null);
  const [userMailVerified, setUserMailVerified] = useState(null);

  useEffect(() => {
    let unsubscribe;
    const unsubscribeAuth = auth.onAuthStateChanged(async (user) => {
      try {
        if (user) {
          const { uid, providerData, emailVerified } = user;
          const method = providerData[0]?.providerId.split(".")[0] || "unknown";
          setAuthMethod(method);
          setUserMailVerified(emailVerified);

          unsubscribe = firestore
            .collection("users")
            .doc(uid)
            .onSnapshot((snapshot) => {
              if (snapshot.exists) {
                setCurrentUser({
                  ...snapshot.data(),
                  firebaseUser: { ...user },
                });
              } else {
                setCurrentUser(null);
              }
            });
        } else {
          setCurrentUser(null);
          setAuthMethod(null);
          setUserMailVerified(null);
        }
      } catch (error) {
        console.error(
          "Erreur lors de la récupération de l'utilisateur :",
          error
        );
      }
    });

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
      unsubscribeAuth();
    };
  }, []);

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        accountLinked,
        setAccountLinked,
        authMethod,
        userMailVerified,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
