import firebase from "firebase/compat";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/functions";
import "firebase/compat/storage";
import "firebase/compat/database";
import { ReCaptchaV3Provider, initializeAppCheck } from "firebase/app-check";

if (process.env.NODE_ENV !== "production") {
  require("dotenv").config();
}

const isMainBranch = process.env.REACT_APP_BRANCH === "main";
const V3sitekeyByConditon = isMainBranch
  ? process.env.REACT_APP_SITE_KEY_V3
  : process.env.REACT_APP_SITE_KEY_V3_DEV;

const debugTokenByConditiion = isMainBranch
  ? process.env.REACT_APP_DEBUG_TOKEN
  : process.env.REACT_APP_CHECK_DEBUG_TOKEN_FROM_CI_DEV;

const apiKey = isMainBranch
  ? process.env.REACT_APP_FIREBASE_KEY
  : process.env.REACT_APP_DEVPROD_FIREBASE_KEY;

const authDomain = isMainBranch
  ? process.env.REACT_APP_FIREBASE_DOMAIN
  : process.env.REACT_APP_DEVPROD_FIREBASE_DOMAIN;

const projectId = isMainBranch
  ? process.env.REACT_APP_FIREBASE_PROJECT_ID
  : process.env.REACT_APP_DEVPROD_FIREBASE_PROJECT_ID;

const storageBucket = isMainBranch
  ? process.env.REACT_APP_FIREBASE_STORAGE_BUCKET
  : process.env.REACT_APP_DEVPROD_FIREBASE_STORAGE_BUCKET;

const messagingSenderId = isMainBranch
  ? process.env.REACT_APP_FIREBASE_SENDER_ID
  : process.env.REACT_APP_DEVPROD_FIREBASE_SENDER_ID;
const appId = isMainBranch
  ? process.env.REACT_APP_FIREBASE_APP_ID
  : process.env.REACT_APP_DEVPROD_FIREBASE_APP_ID;

const firebaseConfig = {
  apiKey,
  authDomain,
  projectId,
  storageBucket,
  messagingSenderId,
  appId,
};

const app = firebase.initializeApp(firebaseConfig);
if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  window.FIREBASE_APPCHECK_DEBUG_TOKEN = debugTokenByConditiion;
}

const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider(`${V3sitekeyByConditon}`),
  isTokenAutoRefreshEnabled: true,
});

// firebase.setLogLevel('debug')
// Uncomment below lines if you want to connect to local firebase while environnement is development
if (window.location.hostname === "localhost") {
  firebase.firestore().useEmulator("localhost", 8080);
  // Uncomment this function whenever you need tot test local ressources diff between ENV DEV & PROD
  firebase.app().functions("europe-west3").useEmulator("localhost", 5001);
  firebase.auth().useEmulator("http://localhost:9099");
  firebase.storage().useEmulator("localhost", 9199);
}

// Initializing firebase app with credentials defined above
const auth = firebase.auth();
const firestore = firebase.firestore();
const firestoreRef = firebase.firestore;
const storage = firebase.storage();
const database = firebase.database();
const functions = firebase.app().functions("europe-west3");
const authRef = firebase.auth;

export {
  app,
  firestore,
  firestoreRef,
  auth,
  database,
  storage,
  authRef,
  functions,
};
