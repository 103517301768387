import React, { createContext, useContext, useState, useEffect } from "react";
import { AuthContext } from "../utility/context/AuthProvider";
import { firestore } from "../firebase";

// Création du contexte
const AlertContext = createContext();

// Provider
export const AlertProvider = ({ children }) => {
  const [activeAlerts, setActiveAlerts] = useState([]);
  const { currentUser } = useContext(AuthContext);
  console.log(currentUser);
  const handleCloseAlert = (index) => {
    setActiveAlerts((currentAlerts) =>
      currentAlerts.filter((_, alertIndex) => alertIndex !== index)
    );
  };

  useEffect(() => {
    if (!currentUser) return;

    const getAlertData = async () => {
      let alerts = [];

      // Récupérer les alertes basées sur l'ID de l'utilisateur
      const querySnapshotUserId = await firestore
        .collection("alerts")
        .where("visibleTo", "array-contains", currentUser.user_id)
        .where("isActive", "==", true)
        .get();

      querySnapshotUserId.forEach((doc) => {
        if (doc.exists) {
          alerts.push(doc.data());
        }
      });

      // Récupérer les alertes basées sur le rôle de l'utilisateur
      const querySnapshotRole = await firestore
        .collection("alerts")
        .where("visibleTo", "array-contains", currentUser.role)
        .where("isActive", "==", true)
        .get();

      querySnapshotRole.forEach((doc) => {
        if (doc.exists) {
          alerts.push(doc.data());
        }
      });

      // Si l'utilisateur est un super-administrateur, récupérer également les alertes pour les administrateurs
      if (currentUser.role === "SuperAdmin") {
        const querySnapshotAdmin = await firestore
          .collection("alerts")
          .where("visibleTo", "array-contains", "Admin")
          .where("isActive", "==", true)
          .get();

        querySnapshotAdmin.forEach((doc) => {
          if (doc.exists) {
            alerts.push(doc.data());
          }
        });
      }

      // Supprimer les doublons éventuels
      alerts = [...new Set(alerts)];

      setActiveAlerts(alerts);
    };

    getAlertData();
  }, [currentUser]);

  return (
    <AlertContext.Provider value={{ activeAlerts, handleCloseAlert }}>
      {children}
    </AlertContext.Provider>
  );
};

// Hook personnalisé pour utiliser le contexte
export const useAlerts = () => useContext(AlertContext);
