import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "./AuthProvider";
import { firestore } from "../../firebase";

// Your collectionConfig goes here
const collectionConfig = {
  Admin: {
    devis_client: "quoteCustomer",
    Logs: "queryLogs",
    mails: "mails",
    sms: "smsTemplate",
    gestion_sms: "sendedSms",
    clients: "users",
  },
  Client: {
    my_count: "registred_counts",
    my_quotes: "quoteCustomer",
  },
  Commercial: {
    myCount: "registred_counts",
    sms: "smsTemplate",
    gestion_sms: "sendedSms",
  },
  Anonymous: {
    usersQuote: "quoteCustomer",
    usersRegisteredCount: "registred_counts",
  },
};

const CollectionCountContext = React.createContext({});

export const CollectionCountProvider = ({ children }) => {
  const { currentUser: user } = useContext(AuthContext);
  const [collectionCounts, setCollectionCounts] = useState({});

  useEffect(() => {
    if (!user) return;
    const listeners = [];

    for (const [itemId, collectionName] of Object.entries(
      collectionConfig[user?.role] || {}
    )) {
      let query;
      if (user?.role === "Admin") {
        query = firestore.collection(collectionName);
      } else {
        query = firestore
          .collection(collectionName)
          .where("user_id", "==", user?.user_id);
      }

      const unsubscribe = query.onSnapshot((snapshot) => {
        setCollectionCounts((prevCounts) => ({
          ...prevCounts,
          [itemId]: snapshot.size,
        }));
      });
      listeners.push(unsubscribe);
    }

    return () => {
      listeners.forEach((unsubscribe) => unsubscribe());
    };
  }, [user]);

  return (
    <CollectionCountContext.Provider value={collectionCounts}>
      {children}
    </CollectionCountContext.Provider>
  );
};

export default CollectionCountContext;
